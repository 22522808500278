import { MappingStatus } from './types';

export const ACTION = {
  VIEW: 'view',
  MAP: 'map',
  IGNORE: 'ignore',
  EDIT: 'edit',
  REVIEW: 'review',
} as const;

export const MAPPING_RULE_ACTION = {
  VIEW: 'viewMappingRule',
  EDIT: 'editMappingRule',
  DELETE: 'deleteMappingRule',
  REORDER: 'reorderMappingRule',
} as const;

export const MAPPING_STATUSES = {
  unmapped: 'UNMAPPED',
  failure: 'FAILURE',
  success: 'SUCCESS',
  pending: 'PENDING',
  oneOff: 'ONEOFF',
  ignore: 'IGNORE',
} as const;

export const MAPPING_STATUS_DISPLAY_VALUES = {
  UNMAPPED: 'Unmapped',
  FAILURE: 'Failure',
  SUCCESS: 'Success',
  ONEOFF: 'One-off',
  PENDING: 'Review Pending',
  IGNORE: 'Ignored',
};

export const MAPPING_RULE_COMPARATORS = {
  LESS_THAN: 'less than',
  GREATER_THAN: 'greater than',
  EQUALS: 'equals',
  NOT_EQUALS: 'not equals',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not contains',
  STARTS_WITH: 'starts with',
  ENDS_WITH: 'ends with',
  IS_EMPTY: 'is empty',
  IS_NOT_EMPTY: 'is not empty',
  IS_ANY_OF: 'is any of',
} as const;

export const MAP_TO_FIELDS = {
  downstream_id: 'Platform',
  platform_legal_name: 'Platform Legal Name',
  client_username: 'Client Username',
  client_account_id: 'Client Account ID',
};

export const MAPPING_RULE_FIELDS = {
  transaction_date: 'Transaction Date',
  transaction_type: 'Transaction Type',
  payment_method: 'Transfer Type',
  symbol: 'Symbol',
  net_amount: 'Quantity',
  payer_name: 'Payer Name',
  destination_address: 'Deposit Destination',
  source_address: 'Deposit Source',
  order_ref: 'Order Reference',
  bank_account_id: 'Bank Account ID',
  memo_main: 'Memo Main',
  memo_additional: 'Memo Additional',
} as const;

export const TRANSACTION_FIELDS = {
  ...MAPPING_RULE_FIELDS,
  transaction_id: 'Transaction ID',
  mapping_status: 'Mapping Status',
  external_status: 'External Status',
  internal_status: 'Internal Status',
  transmission_status: 'Transmission Status',
  internal_tx_id: 'Internal TX ID',
  rule_id: 'Rule ID',
  platform: 'Platform',
  platform_legal_name: 'Platform Legal Name',
  client_user_name: 'Client User Name',
  client_account_id: 'Client Account ID',
  approved_by: 'Approved By',
  raw_transaction_data: 'Raw Transaction Data',
} as const;

export const NON_ACTIONABLE_STATUSES: MappingStatus[] = [
  MAPPING_STATUSES.success,
  MAPPING_STATUSES.ignore,
  MAPPING_STATUSES.oneOff,
];

export const MAPPING_RULES_ACTION_MENU_ITEMS = [
  {
    actionType: MAPPING_RULE_ACTION.VIEW,
    label: 'View',
  },
  {
    actionType: MAPPING_RULE_ACTION.EDIT,
    label: 'Edit',
  },
  {
    actionType: MAPPING_RULE_ACTION.REORDER,
    label: 'Reorder',
  },
  {
    actionType: MAPPING_RULE_ACTION.DELETE,
    label: 'Delete',
    sx: { color: 'error.main' },
  },
];

export const TRANSACTIONS_MENU_ITEMS = [
  { label: 'View', actionType: ACTION.VIEW },
  { label: 'Map Transaction', actionType: ACTION.MAP },
  { label: 'Ignore', actionType: ACTION.IGNORE },
];

export const MODAL_TITLES = {
  [ACTION.VIEW]: 'View Transaction',
  [ACTION.REVIEW]: 'Review Transaction',
  [ACTION.MAP]: 'Map Transaction',
  [ACTION.IGNORE]: 'Ignore Transaction',
  [MAPPING_RULE_ACTION.VIEW]: 'View Mapping Rule',
  [MAPPING_RULE_ACTION.EDIT]: 'Edit Mapping Rule',
  [MAPPING_RULE_ACTION.DELETE]: 'Delete Mapping Rule',
  [MAPPING_RULE_ACTION.REORDER]: 'Reorder Mapping Rule',
};

export const BUTTON_LABELS = {
  save: 'Save',
  cancel: 'Cancel',
  submit: 'Submit',
  confirm: 'Confirm',
  create: 'Create',
  edit: 'Edit',
  close: 'Close',
  delete: 'Delete',
};
