// converts epoch milliseconds to yyyy/mm/dd
import moment from 'moment/moment';

export function convertEpochDate(epochTime) {
  return epochTime.value ? new Date(epochTime.value).toISOString().slice(0, 10) : '';
}

// converts epoch milliseconds to hh:mm:ss
export function convertEpochTime(epochTime) {
  return epochTime.value
    ? new Date(epochTime.value).toLocaleTimeString(undefined, {
        hour12: false,
      })
    : '';
}

// converts epoch milliseconds start and end time to a mm/dd/yyyy - mm/dd/yyyy time range
export function convertEpochDateRange(epochStartTime, epochEndTime) {
  if (epochStartTime && epochEndTime) {
    const fromDate = new Date(epochStartTime).toISOString().slice(0, 10);
    const toDate = new Date(epochEndTime).toISOString().slice(0, 10);
    return `${fromDate} - ${toDate}`;
  }
  return '';
}

// convert milliseconds to MM/DD/YYYY - hh:mm:ss AM/PM UTC
export function convertEpochTimeUTC(tradeTime, format = 'MM/DD/YYYY - hh:mm:ss A') {
  const momentDate = moment.utc(tradeTime);
  const tradeTimeUTC = momentDate.format(format) + ' UTC';

  return tradeTimeUTC;
}

// The function takes a time range (presumably an array of two dates)
// and returns an object with startTime and endTime properties,
// where startTime is the start of the first day and endTime is the end of the second day.
export function getDateRangeWithDayBounds(timeRange) {
  const [start, end] = timeRange;

  const startTime = moment(start).startOf('day').valueOf();
  const endTime = moment(end).endOf('day').valueOf();

  return { startTime, endTime };
}

// returns day at 12:00:00 AM UTC in milliseconds based on offset from today
// (i.e., offset=0:today, offset=1:tomorrow)
export function getDayStartUTC(offset = 0) {
  const todayStartUTC = moment.utc().startOf('day');
  const dayWithOffset = todayStartUTC.add(offset, 'days');
  return dayWithOffset.valueOf();
}

/**
 * Converts timestamp to a locale string with only hours and minutes.
 * @param {number} timestamp
 * @returns {string}
 */
export function getTimeLocaleString(timestamp) {
  return new Date(timestamp).toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}
